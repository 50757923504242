import { createSelector } from 'reselect'
import moment from 'moment'
import { locationSorter } from '../../shared/sorters/location-sorter'

const allLocations = state => state.locationSearch.locations
const sortOrder = state => state.locationSearch.sortOrder
const locationsByAppointmentDateTime = state => state.locationSearch.locationsByAppointmentDateTime
export const getSelectedWeekendHours = state => state.locationSearch.filters.selectedWeekendHours || ''
export const getSearchLocation = state => state.searchInput.searchLocation || {}
export const getSelectedDate = state => {
  if (state.locationSearch) {
    return state.locationSearch.filters.selectedAppointmentDate || ''
  } else {
    return ''
  }
}
export const getSelectedTimeSlots = state => state.locationSearch.filters.selectedTimeSlots || []

const timesByDate = createSelector(getSelectedDate, locationsByAppointmentDateTime, (date, locationAppointments) => {
  date = moment(date).format('YYYY-MM-DD')
  return locationAppointments && locationAppointments[date] && date !== '' ? Object.assign({}, locationAppointments[date]) : {}
})

const locationIdsByTimeAndDate = createSelector(timesByDate, getSelectedTimeSlots, (timesByDate, timeSelections) => {
  let times = Object.keys(timesByDate)
  if (times && times.length) {
    if (timeSelections && timeSelections.length) {
      let timeRanges = timeSelections.map((time) => ({
        start: Number(time.substr(0, 2)),
        end: Number(time.substr(2, 2))
      }))
      times = times.filter(time => {
        let show = false
        let timeMoment = moment(time, 'HH:mm:ss', true)
        timeRanges.forEach(range => {
          if (timeMoment.isValid() && timeMoment.hour() >= range.start && timeMoment.hour() < range.end) {
            show = true
          }
        })
        return show
      })
    }
    return times.reduce((locations, time) => {
      timesByDate[time].forEach(id => { if (!locations.includes(id)) locations.push(id) })
      return locations
    }, [])
  } else {
    return []
  }
})

export const locationFilteringDateFetched = createSelector(locationsByAppointmentDateTime, getSelectedDate, (locationsByAppointmentDateTime, locationFilterDate) => {
  let dateString = moment(locationFilterDate).format('YYYY-MM-DD')
  return locationsByAppointmentDateTime.hasOwnProperty(dateString)
})

const getLocationsByAvailability = createSelector(allLocations, locationIdsByTimeAndDate, getSelectedWeekendHours, getSelectedDate, (locations, locationIdsByTimeAndDate, selectedWeekendHours, selectedDate) => {
  return locations.filter(location => {
    if (selectedDate || (selectedWeekendHours && selectedWeekendHours.length)) {
      let shown = selectedDate ? locationIdsByTimeAndDate.includes(String(location.Id)) : true
      return selectedWeekendHours && selectedWeekendHours.length ? (location.OpenSat || location.OpenSun) && shown : shown
    }
    return true
  })
})

export const getCityFilter = state => state.locationSearch.filters.selectedCity

export const getFilteredLocations = createSelector(getLocationsByAvailability, getCityFilter, sortOrder, (getLocationsByAvailability, cityFilter, sortOrder) => {
  return locationSorter(getLocationsByAvailability.filter(location => { return cityFilter ? location.City === cityFilter : true }), sortOrder)
})

export const getFilteredLocationsCount = createSelector(getFilteredLocations, (locations) => {
  return locations.length
})

export const getLocationCities = createSelector(allLocations, (locations) => {
  return locations && locations.length ? locations.reduce((cities, location) => {
    if (cities.indexOf(location.City) === -1) {
      cities.push(location.City)
    }
    return cities.sort((a, b) => { return a >= b ? 1 : -1 })
  }, []) : []
})

export const getWeekendHours = createSelector(allLocations, (locations) => {
  let found = locations && locations.length ? locations.filter(location => location.OpenSat || location.OpenSun) : null
  if (found && found.length > 0) {
    return ['Offers Weekend Hours']
  }
  return []
})

export const getLocationFilterCount = createSelector(getSelectedDate, getSelectedTimeSlots, getSelectedWeekendHours, getCityFilter, (date, slots, hours, city) => {
  let count = 0
  if (date && date !== '') count += 1
  if (slots && slots.length > 0) count += 1
  if (hours && hours.length > 0) count += 1
  if (city && city !== '') count += 1
  return count
})

export const getTimeSlots = createSelector(() => {
  return [
    { label: '6 - 9 a.m.', value: '0609' },
    { label: '9 a.m. - 12 p.m.', value: '0912' },
    { label: '12 - 3 p.m.', value: '1215' },
    { label: '3 - 6 p.m.', value: '1518' }
  ]
})

export const getMAMSortOptions = createSelector(getFilteredLocations, (locations) => {
  let sortOptions = [
    { value: 'Distance', label: 'Distance' },
    { value: 'A TO Z', label: 'Name: A TO Z' }
  ]
  let isDistanceOptionDisabled = true
  if (locations && locations.length > 0) {
    if (locations[0].Distance != null) {
      isDistanceOptionDisabled = false
    }
  }

  sortOptions[0].disabled = isDistanceOptionDisabled
  sortOptions[0].selected = !isDistanceOptionDisabled
  sortOptions[1].disabled = !(locations && locations.length)

  return sortOptions
})
