import React from 'react'
import FilterConnector from '../Filter/FilterConnector.jsx'
import DateFiltersConnector from '../Filter/DateFiltersConnector.jsx'
import SkeletonFilters from '../SkeletonFilters/SkeletonFilters.jsx'
import { getSpecialtiesFromAllDoctors, getAvailabilityFromAllDoctors, getDoctorTypeFromAllDoctors, getGendersFromAllDoctors, getLanguagesFromAllDoctors, getHospitalFromAllDoctors, getAffiliationFromAllDoctors, getScheduleFromAllDoctors, getAcceptingFromAllDoctors, getProvidersWithRatings } from '../../../selectors/doctor-search-filter-selectors'
import { getClassTypeFromAllClasses, getCategoriesFromAllClasses, getLocationsFromAllClasses, getTagsFromAllClasses } from '../../../../Blocks/Classes/selectors/classes-selectors'
import { getWeekendHours, getTimeSlots } from '../../../selectors/location-search-filter-selectors'

const defaultToggles = {
  selectedAvailability: { showMore: false, collapse: false },
  selectedSpecialty: { showMore: false, collapse: true },
  selectedDoctorType: { showMore: false, collapse: true },
  selectedGender: { showMore: false, collapse: true },
  selectedLanguage: { showMore: false, collapse: true },
  selectedHospital: { showMore: false, collapse: true },
  selectedAffiliation: { showMore: false, collapse: true },
  selectedClassType: { showMore: false, collapse: false },
  selectedCategories: { showMore: false, collapse: false },
  selectedLocations: { showMore: false, collapse: false },
  selectedTags: { showMore: false, collapse: true },
  selectedRating: { showMore: false, collapse: true }
}

export default class FindFilters extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      filterToggles: JSON.parse(JSON.stringify(defaultToggles))
    }
    this.filterOptions = this.props.filterOptions
    this.searchOptions = this.props.searchOptions
    this.urlParameters = this.props.urlParameters
    this.showHideOptions = this.showHideOptions.bind(this)
    this.toggleCollapse = this.toggleCollapse.bind(this)
  }

  componentDidMount () {
    let baseObject = { ...this.state.filterToggles }
    if (this.urlParameters) {
      let filters = Object.entries(this.urlParameters)
      filters.map((filter) => {
        if (filter[1].length && filter[0] !== 'selectedSpecialty' && this.props.flow !== 'mam' && baseObject[filter[0]].collapse) {
          this.toggleCollapse(filter[0])
        }
      })
    }
  }

  showHideOptions (toggleKey) {
    let toggleCheck
    let baseObject = { ...this.state.filterToggles }
    toggleCheck = !baseObject[toggleKey].showMore
    baseObject[toggleKey].showMore = toggleCheck
    this.setState({
      filterToggles: baseObject
    })
  }

  toggleCollapse (toggleKey) {
    let toggleCheck
    if (toggleKey !== 'selectedAvailability') {
      let baseObject = { ...this.state.filterToggles }
      toggleCheck = !baseObject[toggleKey].collapse
      baseObject[toggleKey].collapse = toggleCheck
      this.setState({
        filterToggles: baseObject
      })
    }
  }

  createFilters () {
    let filters = []
    let toggles = this.state.filterToggles
    if (this.props.flow === 'mam') {
      if (this.filterOptions.showWeekendHours) filters.push(<FilterConnector key={1} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={false} collapse={false} toggleCollapse={this.toggleCollapse} collapseable={false} title='' getOption={getWeekendHours} filterKey='selectedWeekendHours' dispatchType='SET_SELECTED_WEEKEND_HOURS' />)      
      if (this.filterOptions.showTimeSlots && this.props.selectedDate && this.props.dataLoaded) filters.push(<FilterConnector key={3} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={false} collapse={false} className='date-times' toggleCollapse={this.toggleCollapse} collapseable={false} title='TIME FRAME' getOption={getTimeSlots} filterKey='selectedTimeSlots' dispatchType='SET_SELECTED_TIME_SLOTS' />)
    } else if (this.props.flow === 'fad' || this.props.flow === 'cin') {
      if (this.filterOptions.showAcceptingNewScheduling) {
        let options = getAvailabilityFromAllDoctors
        if (this.filterOptions.hideAccepting) {
          options = getScheduleFromAllDoctors
        } else if (this.filterOptions.hideSchedulingFilter) {
          options = getAcceptingFromAllDoctors
        }
        filters.push(<FilterConnector key={1} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedAvailability.showMore} collapse={toggles.selectedAvailability.collapse} toggleCollapse={this.toggleCollapse} collapseable={false} title='AVAILABILITY' getOption={options} filterKey='selectedAvailability' dispatchType='SET_SELECTED_AVAILABILITY' />)
      }
    }
    filters.push(<FilterConnector key={2} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedRating.showMore} collapse={toggles.selectedRating.collapse} toggleCollapse={this.toggleCollapse} collapseable title='RATING' getOption={getProvidersWithRatings} filterKey='selectedRating' dispatchType='SET_SELECTED_RATING' />)
    if (this.filterOptions.showSpeciality) filters.push(<FilterConnector key={3} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedSpecialty.showMore} collapse={toggles.selectedSpecialty.collapse} toggleCollapse={this.toggleCollapse} collapseable title='SPECIALTY' getOption={getSpecialtiesFromAllDoctors} filterKey='selectedSpecialty' dispatchType='SET_SELECTED_SPECIALTY' />)
    if (this.filterOptions.showDoctorType) filters.push(<FilterConnector key={4} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedDoctorType.showMore} collapse={toggles.selectedDoctorType.collapse} toggleCollapse={this.toggleCollapse} collapseable title='DOCTOR TYPE' getOption={getDoctorTypeFromAllDoctors} filterKey='selectedDoctorType' dispatchType='SET_SELECTED_DOCTOR_TYPE' />)
    if (this.filterOptions.showGenderPreference) filters.push(<FilterConnector key={5} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedGender.showMore} collapse={toggles.selectedGender.collapse} toggleCollapse={this.toggleCollapse} collapseable title='GENDER PREFERENCE' getOption={getGendersFromAllDoctors} filterKey='selectedGender' dispatchType='SET_SELECTED_GENDER' />)
    if (this.filterOptions.showAdditionalLanguages) filters.push(<FilterConnector key={6} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedLanguage.showMore} collapse={toggles.selectedLanguage.collapse} toggleCollapse={this.toggleCollapse} collapseable title='ADDITIONAL LANGUAGES' getOption={getLanguagesFromAllDoctors} filterKey='selectedLanguage' dispatchType='SET_SELECTED_LANGUAGE' />)
    if (this.filterOptions.showHospital) filters.push(<FilterConnector key={7} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedHospital.showMore} collapse={toggles.selectedHospital.collapse} toggleCollapse={this.toggleCollapse} collapseable title='HOSPITAL' getOption={getHospitalFromAllDoctors} filterKey='selectedHospital' dispatchType='SET_SELECTED_HOSPITAL' />)
    if (this.filterOptions.showAffiliation) filters.push(<FilterConnector key={8} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedAffiliation.showMore} collapse={toggles.selectedAffiliation.collapse} toggleCollapse={this.toggleCollapse} collapseable title='AFFILIATION' getOption={getAffiliationFromAllDoctors} filterKey='selectedAffiliation' dispatchType='SET_SELECTED_AFFILIATION' />)
    if (this.filterOptions.showClassType) filters.push(<FilterConnector key={9} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedClassType.showMore} collapse={toggles.selectedClassType.collapse} toggleCollapse={this.toggleCollapse} collapseable title='CLASS TYPE' getOption={getClassTypeFromAllClasses} filterKey='selectedClassType' dispatchType='SET_SELECTED_CLASS_TYPE' />)
    if (this.filterOptions.showCategory) filters.push(<FilterConnector key={10} getSubFilters={getTagsFromAllClasses} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedCategories.showMore} collapse={toggles.selectedCategories.collapse} toggleCollapse={this.toggleCollapse} collapseable title='CATEGORY' getOption={getCategoriesFromAllClasses} filterKey='selectedCategories' dispatchType='SET_SELECTED_CLASS_CATEGORY' subFilterKey='selectedTags' subFilterDispatchType='SET_SELECTED_CLASS_TAG' />)
    if (this.filterOptions.showLocations) filters.push(<FilterConnector key={11} searchOptions={this.searchOptions} showHideOptions={this.showHideOptions} showMore={toggles.selectedLocations.showMore} collapse={toggles.selectedLocations.collapse} toggleCollapse={this.toggleCollapse} collapseable title='LOCATION' getOption={getLocationsFromAllClasses} filterKey='selectedLocations' dispatchType='SET_SELECTED_CLASS_LOCATION' />)
    return filters
  }

  render () {
    if (this.props.mobile) {
      return <div>{this.createFilters()}</div>
    } else if (!this.props.dataLoaded && this.props.flow !== 'mam') {
      return <SkeletonFilters flow={this.props.flow} filterOptions={this.filterOptions} />
    } else {
      return <div>{this.createFilters()}</div>
    }
  }
}
