export const DEFAULT_SEARCH_LOCATION = {
  within: 25
}

export const DEFAULT_STATE = {
  searchLocation: DEFAULT_SEARCH_LOCATION,
  visitTypeId: '1341'
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_SEARCH_QUERY':
      return { ...state, ...{ searchQuery: action.value } }
    case 'SET_LOCATION_QUERY':
      return { ...state, ...{ searchLocation: { address: action.value } } }
    case 'SET_SELECTED_SEARCH_LOCATION':
      return { ...state, ...{ searchLocation: action.value } }
    case 'SET_VISIT_TYPE':
      return { ...state, ...{ visitTypeId: action.id } }
    case 'GET_MAM_LOCATIONS':
      return { ...state, ...{ searchLocation: { address: action.params.locq, latitude: action.params.lat, longitude: action.params.lng } } }
  }

  return state
}
