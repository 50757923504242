export const DEFAULT_SEARCH_LOCATION = {
  dist: 5
}
const DEFAULT_SORT_ORDER = 'A TO Z'
export const DEFAULT_STATE = {  
  sortOrder: DEFAULT_SORT_ORDER,
  searchedBounds: null,
  searchedBoundsObj: null,
  autoSearchedLocation: DEFAULT_SEARCH_LOCATION
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_LOCATIONS':
      return { ...state, ...{ locations: action.value, dataLoaded: true } }
    case 'SET_AUTO_LOCATION_QUERY':
      return { ...state, ...{ autoSearchedLocation: { address: action.value } } }
    case 'SET_AUTO_SELECTED_SEARCH_LOCATION':
      return { ...state, ...{ autoSearchedLocation: action.value } }
    case 'SET_LOCATION_SORT_ORDER':
      return { ...state, ...{ sortOrder: action.value } }
    case 'SET_SELECTED_WEEKEND_HOURS':
      return { ...state, ...{ filters: { ...state.filters, selectedWeekendHours: action.value } } }
    case 'SET_LOCATION_CURRENT_PAGE':
      return { ...state, ...{ currentPage: action.number } }
    case 'GET_MAM_LOCATIONS':
      return { ...state, ...{ searchLocation: { address: action.params.locq, latitude: action.params.lat, longitude: action.params.lng } } }
  }
  return state
}
