import React from 'react'
import Cookies from 'js-cookie'
import './locationInput.scss'
import InputText from '../../../../shared/components/InputText'
import SVGInline from 'react-svg-inline'
import LocationIcon from '../../../../shared/assets/map-marker.svg'
import { updateCookie, GeolocationAcceptOrDeny } from '../../../../shared/utility-functions'

export default class LocationInput extends React.Component {
  constructor (props) {
    super(props)
    this.autocomplete = null
    this.inputElement = null
    this.geocoderService = new google.maps.Geocoder()
    this.autocompleteService = new google.maps.places.AutocompleteService()
    this.onBlurHandler = this.onBlurHandler.bind(this)
    this.inputIsNotGooglePlace = this.inputIsNotGooglePlace.bind(this)
    this.state = {}
  }

  componentDidMount () {
    this.getGeoLocationFromBrowser()
    this.initializeGoogleAutoComplete()
  }

  componentWillUnmount () {
    google.maps.event.clearInstanceListeners(this.autocomplete)
  }

  initializeGoogleAutoComplete () {
    let options = {
      types: ['geocode'],
      componentRestrictions: { country: 'us' },
      location: {
        latitude: 40.112450,
        longitude: -82.666185,
        lat: () => { return 40.112450 },
        lng: () => { return -82.666185 }
      },
      radius: 194847,
      strictbounds: true
    }
    this.autocomplete = new google.maps.places.Autocomplete(this.inputElement, options)
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => this.googlePlaceChangedHandler())
  }

  getGooglePlacePredictions () {
    let options = {
      input: this.props.address,
      types: ['geocode'],
      componentRestrictions: { country: 'us' },
      location: {
        latitude: 40.112450,
        longitude: -82.666185,
        lat: () => { return 40.112450 },
        lng: () => { return -82.666185 }
      },
      radius: 194847,
      strictbounds: true
    }

    this.autocompleteService.getPlacePredictions(options, (predictions) => {
      if (predictions && predictions.length) {
        let match = predictions.filter(prediction => {
          return prediction.types && prediction.types.length > 0 && prediction.types.filter(type => type === 'postal_code')[0]
        })[0]
        if (!match) {
          match = predictions[0]
        }
        this.getGoogleGeoLocationData(match)
      }
    })
  }

  getLatLngFromCookie () {
    let latlng = {}
    let existingCookie = Cookies.get('fad_search') ? JSON.parse(Cookies.get('fad_search')) : []
    if (existingCookie.length) {
      existingCookie.map((param) => {
        if (param.name === 'lat') {
          latlng.lat = param.value
        }
        if (param.name === 'lng') {
          latlng.lng = param.value
        }
      })
    }
    return latlng
  }

  getGeoLocationFromBrowser () {
    let cookie = this.getLatLngFromCookie()
    if (cookie.lat && cookie.lng) {
      this.geocoderService.geocode({ location: {lat: Number(cookie.lat), lng: Number(cookie.lng)} }, (place) => this.setGoogleLocationData(place))
    } else {
      let geoPermission = window.localStorage.getItem('geoPermission')
      if (navigator.geolocation && geoPermission === null) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.geocoderService.geocode({ location: {lat: position.coords.latitude, lng: position.coords.longitude} },
            (place) => {
              this.setGoogleLocationData(place)
              GeolocationAcceptOrDeny('accepted')
            })
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            window.localStorage.setItem('geoPermission', 'false')
            GeolocationAcceptOrDeny('denied')
          }
        })
      }
    }
  }

  getGoogleGeoLocationData (prediction) {
    this.geocoderService.geocode({ placeId: prediction.place_id }, (place) => this.setGoogleLocationData(place))
  }

  setGoogleLocationData (place) {
    let address = place[0].formatted_address || place[0].name
    let geolocation = place[0].geometry.location
    let lat = geolocation.lat()
    let lng = geolocation.lng()
    this.setState({ lat, lng, address })
    this.props.setLocation(address, lat, lng)
    updateCookie({locq: address, lat: lat, lng: lng}, 'fad_search')
    if(this.props.flow === 'mam'){
      updateCookie({searchClicked: true}, 'mam_search_clicked')
    }
  }

  getGeolocation (place) {
    let lat = null
    let lng = null
    let address = place.formatted_address || place.name
    if (place.geometry && place.geometry.location) {
      lat = place.geometry.location.lat()
      lng = place.geometry.location.lng()
    }

    this.setState({ lat, lng, address })
    this.props.setLocation(address, lat, lng)
    updateCookie({locq: address, lat: lat, lng: lng}, 'fad_search')
  }

  inputIsNotGooglePlace (place) {
    return !place.place_id && this.props.address
  }

  googlePlaceChangedHandler () {
    let place = this.autocomplete.getPlace()
    if (this.inputIsNotGooglePlace(place)) {
      this.getGooglePlacePredictions()
    } else {
      this.getGeolocation(place)
    }
  }

  onBlurHandler () {
    if (this.state.address !== this.props.address || !this.state.lat) {
      this.getGooglePlacePredictions()
    }
  }

  clearResults () {
    this.setState({ lat: null, lng: null, address: null })
    this.props.setLocation(null, null, null)
    updateCookie(null, 'fad_search')
    removeItem('addressData')
    if(this.props.flow === 'mam'){
      updateCookie({searchClicked: false}, 'mam_search_clicked')
    }
  }

  restrictGeoLocation () {
    var geolocation = {
      lat: 39.962761,
      lng: -82.996292
    }
    var circle = new google.maps.Circle({center: geolocation, radius: 196340})
    this.autocomplete.setBounds(circle.getBounds())
    this.autocomplete.setOptions({strictBounds: true})
  }

  render () {
    let clearInputClass = 'clear-input-off'
    if (this.props.address) {
      clearInputClass = 'clear-input'
    }
    return (
      <div className='location-input'>
        <SVGInline className='location-input-icon' svg={LocationIcon} />
        <InputText
          title='Location Input'
          placeholder='City, street or zip code'
          maxLength='100'
          onBlurHandler={(event) => this.onBlurHandler()}
          onChangeHandler={(event) => this.props.setLocation(event.target.value)}
          onFocusHandler={(event) => this.restrictGeoLocation(event)}
          onKeyUpHandler={(event) => { if (this.props.enterSubmit) { this.props.enterSubmit(event) } }}
          value={this.props.address || ''}
          inputRef={input => { this.inputElement = input }}
          inputId={this.props.mobile ? 'LOCATION_INPUT_MOBILE' : 'LOCATION_INPUT'}
        />
        <div className={clearInputClass} onClick={() => this.clearResults()}><img className='clear-icon' src={'/ClientResources/Website/images/x.png'} alt='clear search button' /></div>
      </div>
    )
  }
}
