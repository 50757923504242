import React from 'react'
import SVGInline from 'react-svg-inline'
import ArrowRight from '../../../shared/assets/arrow-right.svg'
import './error-modal.scss'

const isSuperMammoScheduling = window.sessionStorage.getItem('isSuperMammoScheduling');

export default class ErrorModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.props.error
  }
  render () {
    return (
      <div className='error-modal'>
        {this.state.icon && <SVGInline svg={this.state.icon} className='error-icon' />}
        <div className='modal-text'>
          <div className='title'>{this.state.title ? this.state.title : 'Notice'}</div>
          <div className='subheader' dangerouslySetInnerHTML={{__html: this.state.text}} />
        </div>
        {isSuperMammoScheduling && this.state.actions ? (
          <div className='btn-container'>
            {this.state.actions.map(
              (it) =>
                it.url &&
                it.urlTitle && (
                  <div className='continue-link'>
                    <a
                      href={it.url}
                      className={it.className}
                      target='_self'>
                      <span className='text'>{it.urlTitle}</span> <SVGInline svg={ArrowRight} />
                    </a>
                  </div>
                )
            )}
          </div>
        ) : (
          this.state.url &&
          this.state.urlTitle && (
            <div className='continue-link'>
              <a
                href={this.state.url}
                className={this.state.className}
                target='_self'>
                <span className='text'>{this.state.urlTitle}</span> <SVGInline svg={ArrowRight} />
              </a>
            </div>
          )
        )}
      </div>
    )
  }
}
