import React, { useRef, useState } from "react";
import moment from "moment";
import { isDateOfBirthValid, isMonthValid, isDayValid, isYearValid } from "../../../shared/validators/date-of-birth-validator";
import "./dob-modal.scss";

export default function DOBModal(props) {
  const [dirty, setDirty] = useState(false);
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [monthValid, setMonthValid] = useState(false);
  const [dayValid, setDayValid] = useState(false);
  const [yearValid, setYearValid] = useState(false);
  const [monthDirty, setMonthDirty] = useState(false);
  const [dayDirty, setDayDirty] = useState(false);
  const [yearDirty, setYearDirty] = useState(false);
  const monthRef = useRef();
  const dayRef = useRef();
  const yearRef = useRef();
  const buttonRef = useRef();

  const isSuperMammoScheduling = window.sessionStorage.getItem("isSuperMammoScheduling") || false;

  const changeHandler = (event, maxLength) => {
    if (event.target.value.length === maxLength) {
      let nextElement = null;
      switch (event.target.name) {
        case "month":
          setMonth(event.target.value)
          nextElement = dayRef;
          break;
        case "day":
          setDay(event.target.value)
          nextElement = yearRef;
          break;
        case "year":
          setYear(event.target.value)
          nextElement = buttonRef;
          break;
      }
      if (nextElement) {
        nextElement.current.focus();
      }
    }
  };

  const showDOBError = () => {
    return singularFieldError() || showDateError();
  };

  const singularFieldError = () => {
    return showSectionError("month") || showSectionError("day") || showSectionError("year");
  };

  const showSectionError = (name) => {
    switch (name) {
      case "day":
        return dayDirty && !dayValid;
      case "month":
        return monthDirty && !monthValid;
      case "year":
        return yearDirty && !yearValid;
    }
  };

  const allFieldsFilled = () => {
    return !!(day && month && year && year.length === 4);
  };

  const showDateError = () => {
    if (!allFieldsFilled()) {
      return false;
    }
    let date = moment(`${Number(year)}-${Number(month)}-${Number(day)}`, "YYYY-MM-DD");
    return !date.isValid() || !isDateOfBirthValid({ day: day, month: month, year: year });
  };

  const validateField = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    switch (name) {
      case "month":
        setMonthValid(isMonthValid(value));
        setMonthDirty(true);
        break;
      case "day":
        setDayValid(isDayValid(value));
        setDayDirty(true);
        break;
      case "year":
        setYearValid(isYearValid(value));
        setYearDirty(true);
        break;
      default:
        break;
    }
    setDirty(true);
  };

  const submit = (event) => {
    event.preventDefault();
    let errorForm = showDOBError();
    if (!errorForm) {
      props.submissionHandler(`${year}-${month}-${day}`);
    }
  };

  const renderForm = () => {
    let allFilled = allFieldsFilled();
    let fieldError = singularFieldError();
    let dateError = showDateError();
    let invalidForm = showDOBError();
    return isSuperMammoScheduling ? (
      <form onSubmit={submit}>
        <div className={dateError && allFilled && !fieldError ? "date-of-birth-input date-of-birth-input--error" : "date-of-birth-input"}>
          <label
            required
            className='text-center'>
            Date of Birth
          </label>
          <div className='input-container'>
            <input
              aria-label='month'
              type='number'
              ref={monthRef}
              id='month'
              name='month'
              className={`field__input month ${showSectionError("month") ? "field__input--error" : ""}`}
              placeholder='MM'
              onBlur={(e)=>validateField(e)}
              onChange={(event) => {
                changeHandler(event, 2);
              }}
              min='1'
              max='12'
              value={month}
            />
            <input
              aria-label='day'
              type='number'
              ref={dayRef}
              id='day'
              name='day'
              className={`field__input day ${showSectionError("day") ? "field__input--error" : ""}`}
              placeholder='DD'
              onBlur={validateField}
              onChange={(event) => {
                changeHandler(event, 2);
              }}
              min='1'
              max='31'
              value={day}
            />
            <input
              aria-label='year'
              type='number'
              ref={yearRef}
              id='year'
              name='year'
              className={`field__input year ${showSectionError("year") ? "field__input--error" : ""}`}
              placeholder='YYYY'
              onBlur={validateField}
              onChange={(event) => {
                changeHandler(event, 4);
              }}
              min='1900'
              value={year}
            />
          </div>
          <div className='btn-container'>
            <button
              type='submit'
              ref={buttonRef}
              disabled={invalidForm || !allFilled}
              className={`btn begin-button ${invalidForm || !allFilled ? "button-disabled" : ""}`}>
              Begin
            </button>
          </div>
          {(dateError || fieldError) && dirty && (
            <div className='field__error show-for-small-only'>Please enter a valid date between Jan 1, 1900 and today.</div>
          )}
        </div>
        {(dateError || fieldError) && dirty && (
          <div className='field__error hide-for-small-only'>Please enter a valid date between Jan 1, 1900 and today.</div>
        )}
        <p className='note'>
          <small>{props.text}</small>
        </p>
      </form>
    ) : (
      <form onSubmit={submit}>
        <div className={dateError && allFilled && !fieldError ? "date-of-birth-input date-of-birth-input--error" : "date-of-birth-input"}>
          <label
            required
            className='text-center'>
            Date of Birth
          </label>
          <input
            aria-label='month'
            type='number'
            ref={monthRef}
            id='month'
            name='month'
            className={`field__input month ${showSectionError("month") ? "field__input--error" : ""}`}
            placeholder='MM'
            onBlur={validateField}
            onChange={(event) => {
              changeHandler(event, 2);
            }}
            min='1'
            max='12'
            value={month}
          />
          <input
            aria-label='day'
            type='number'
            ref={dayRef}
            id='day'
            name='day'
            className={`field__input day ${showSectionError("day") ? "field__input--error" : ""}`}
            placeholder='DD'
            onBlur={validateField}
            onChange={(event) => {
              changeHandler(event, 2);
            }}
            min='1'
            max='31'
            value={day}
          />
          <input
            aria-label='year'
            type='number'
            ref={yearRef}
            id='year'
            name='year'
            className={`field__input year ${showSectionError("year") ? "field__input--error" : ""}`}
            placeholder='YYYY'
            onBlur={validateField}
            onChange={(event) => {
              changeHandler(event, 4);
            }}
            min='1900'
            value={year}
          />
          {(dateError || fieldError) && dirty && (
            <div className='field__error show-for-small-only'>Please enter a valid date between Jan 1, 1900 and today.</div>
          )}
          <button
            type='submit'
            ref={buttonRef}
            disabled={invalidForm || !allFilled}
            className={`btn begin-button ${invalidForm || !allFilled ? "button-disabled" : ""}`}>
            Begin
          </button>
        </div>
        {(dateError || fieldError) && dirty && (
          <div className='field__error hide-for-small-only'>Please enter a valid date between Jan 1, 1900 and today.</div>
        )}
        <p className='note'>
          <small>{props.text}</small>
        </p>
      </form>
    );
  };

  return (
    <div className='dob-modal'>
      <div className='title'>{props.title}</div>
      <p className='explanation'>{props.prompt}</p>
      {renderForm()}
    </div>
  );
}
